<template>
	<div class="form form-horizontal form-scrollable">
		<div class="modal-body">
			<div class="form-row">
				<!-- NOMBRE -->
				<div class="form-group col-md-12">
					<label class="col-form-label label-modal" for="name">Nombre</label>
					<input autocomplete="off" v-model="source.name" type="text" name="name" class="form-control form-control-sm" v-validate="'required'" data-vv-as="'Nombre'" />
					<span v-show="errors.has('name')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('name') }} </span>
					<ErrorBackendComponent :errorsBackend="errorsBackend" name="source.name" />
					<ErrorBackendComponent :errorsBackend="errorsBackend" name="source" />
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button @click="mtd_send" class="btn btn-sm btn-primary mr-3">{{ propInfo.data.buttonSubmit }}</button>
			<button class="btn btn-sm btn-secondary" @click="mtd_close">Cancelar</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
	},
	components: {},
	data() {
		return {
			source: {
				id: null,
				name: null,
			},
			enterprises: [],
			errorsBackend: {},
			methods: {
				init: {
					create: 'this.mtd_onCreate()',
					edit: 'this.mtd_onEdit()',
				},
				action: {
					create: 'this.mtd_store()',
					edit: 'this.mtd_update()',
				},
			},
		};
	},
	computed: {
		...mapGetters(['url_api']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_onCreate: function () {
			this.$emit('onload', { status: 'success' });
		},
		mtd_onEdit: function () {
			this.mtd_setData(this.propInfo.data.row);
			this.$emit('onload', { status: 'success' });
		},
		mtd_store: function () {
			this.post({ url: `${this.url_api}/sources`, params: { source: this.source } })
				.then(res => {
					this.$emit('success', { component: this.propInfo.component, res: { source: res.source }, action: 'store' });
					window.message('success', 'Registro exitoso');
				})
				.catch(errors => (this.errorsBackend = errors));
		},
		mtd_update: function () {
			this.update({ url: `${this.url_api}/sources/${this.source.id}`, params: { source: this.source } })
				.then(res => {
					this.$emit('success', { component: this.propInfo.component, res: { source: res.source }, action: 'update' });
					window.message('success', 'Actualización exitosa');
				})
				.catch(errors => (this.errorsBackend = errors));
		},
		mtd_setData(resource) {
			this.source.id = resource.id;
			this.source.name = resource.name;
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
	},
};
</script>
